// Single-product page template:

import React from "react"
import { Layout, ProductPage } from "../components"

const imageList = [
	{
		link:`/images/featured_medic1.jpg`,
		alt:`Blue aviator flight suit on a white background`,
	},
]


export default function ProductPageMedic() {
	return (
		<Layout
			title="Medic"
		>
			<ProductPage images={imageList}>

				<h2>Medic Flight Suit</h2>
				<p>This suit is designed for the Air Medical personnel, by adding front hand pockets and a nurse pocket on the right leg. You can get the model design in a one color or a two-tone with the Medic II. All leg pockets are positioned on the outside of the legs for easy access to your supplies. This suit has the pockets that are needed so you can put your attention to the patient. </p>

				<h3>Features</h3>
				<ul>
					<li>2 angle chest pockets</li>
					<li>2” x 4” velcro for name tag</li>
					<li>2 thigh pockets, outside of leg</li>
					<li>1 Nurse (EMT) pocket on right leg</li>
					<li>2 lower leg pockets</li>
					<li>Zipper sleeve pocket</li>
					<li>Knife pocket</li>
					<li>Boot zipper entry</li>
				</ul>

			</ProductPage>
		</Layout>
	)
}
